import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';

export class ChatRouteReuseStrategy implements RouteReuseStrategy {
  private _routeStore = new Map<string, DetachedRouteHandle>();

  // Not using attach / detach at the moment
  // Only reusing chat views when switching between screens
  // Not storing them for later reuse when returning

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;

    // return !!route.component.prototype.reuseName?.length;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const identifier = route.component?.prototype?.reuseName;
    this._routeStore.set(identifier, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;

    // const identifier = route.component?.prototype?.reuseName;
    // const shouldAttach =
    //   identifier?.length && !!this._routeStore.get(identifier);
    // return shouldAttach;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const identifier = route.component?.prototype?.reuseName;
    return identifier?.length && this._routeStore.get(identifier);
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const futureId = future.component?.prototype?.reuseName;
    const currId = curr.component?.prototype?.reuseName;
    const isReusable =
      futureId?.length && currId?.length && futureId === currId;
    return future.routeConfig === curr.routeConfig || isReusable;
  }
}

export interface ReusableComponent {
  get reuseName(): string;
}

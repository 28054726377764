import { APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFunctions } from '@angular/fire/functions';
import { provideStorage } from '@angular/fire/storage';
import { getFunctions } from '@angular/fire/functions';
import { getStorage } from '@angular/fire/storage';

// 3rd party
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { QuillModule } from 'ngx-quill';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Libs
import {
  getSharedProviders,
  getAntProviders,
  DeviceService,
  ShareService,
  ApiService,
  AnalyticsService,
  ContentInteractionsService,
  ContentManagementService,
  ContentService,
  CustomerContentService,
  FirestoreFileUploadService,
  LoginModule,
  CustomerDeviceService,
  SearchService,
  AccountStoreService,
  AiService,
  ErrorAlerter,
  DrawerService
} from 'shared';
import {
  MockDeviceService,
  MockShareService,
  MockApiService,
  MockAnalyticsService,
  GlobalLoadingIndicatorViewModule,
  MessageModule,
  MockAiService,
  MockContentInteractionsService,
  MockContentManagementService,
  MockContentService,
  MockFileUploadService,
  MockSearchService
} from 'uikit';
import { MockAccountStoreService, MockDrawerService } from 'customer-uikit';
import {
  BILLING_PORTAL_URL_TOKEN,
  CHAMELEON_API_KEY,
  CHAMELEON_FAST_URL,
  PROFITWELL_API_KEY,
  GTM_ID,
  CLOUD_ANALYTICS_URL_TOKEN,
  IS_NORBY_NEXT
} from 'models';
import { ChatRouteReuseStrategy } from './classes/chat-reuse-strategy';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModule,
    GlobalLoadingIndicatorViewModule,
    MessageModule.forRoot(),

    // Third party
    QuillModule.forRoot(),
    NzModalModule,
    NzDrawerModule,
    NzNotificationModule
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions()),

    // App
    ...getSharedProviders(environment),
    ...getAntProviders(environment),
    { provide: APP_ID, useValue: 'next' },

    { provide: BILLING_PORTAL_URL_TOKEN, useValue: environment.billingBaseUrl },
    {
      provide: CHAMELEON_API_KEY,
      useValue: environment?.chameleonConfig?.apiKey
    },
    {
      provide: CHAMELEON_FAST_URL,
      useValue: environment?.chameleonConfig?.fastUrl
    },
    {
      provide: PROFITWELL_API_KEY,
      useValue: environment?.profitWellConfig?.apiKey
    },
    {
      provide: GTM_ID,
      useValue: environment?.gtmConfig?.id
    },
    {
      provide: CLOUD_ANALYTICS_URL_TOKEN,
      useValue: environment.cloudAnalyticsUrl
    },
    {
      provide: DeviceService,
      useClass: CustomerDeviceService
    },
    {
      provide: MockDeviceService,
      useClass: CustomerDeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockContentInteractionsService,
      useExisting: ContentInteractionsService
    },
    {
      provide: MockSearchService,
      useExisting: SearchService
    },
    {
      provide: MockFileUploadService,
      useClass: FirestoreFileUploadService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockAnalyticsService,
      useClass: AnalyticsService
    },
    {
      provide: MockAiService,
      useExisting: AiService
    },
    {
      provide: MockContentService,
      useClass: CustomerContentService
    },
    {
      provide: MockAccountStoreService,
      useClass: AccountStoreService
    },
    {
      provide: MockContentManagementService,
      useClass: ContentManagementService
    },
    {
      provide: MockDrawerService,
      useClass: DrawerService
    },
    {
      provide: ContentService,
      useClass: CustomerContentService
    },
    {
      provide: RouteReuseStrategy,
      useClass: ChatRouteReuseStrategy
    },
    {
      provide: IS_NORBY_NEXT,
      useValue: true
    },
    ...(!environment.production
      ? [
          {
            provide: ErrorHandler,
            useClass: ErrorAlerter
          }
        ]
      : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
